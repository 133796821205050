import { VStack, useBreakpointValue } from '@chakra-ui/react'
import LegacyHeader from 'atg/components/legacy-header-r2'
import { GlobalSearch, MegaMenuDetailsBar, useComposable } from 'composable'
import {
  algoliaIndexNameResolver,
  algoliaQuerySuggestionsIndexNameResolver,
} from 'composable/components/global-search-shared/utils'
import { FrontasticImage } from 'frontastic/lib/image'
import { useAtgUser } from '../../../contexts'

type TasticProps = {
  data: {
    logo?: FrontasticImage & { title: { en_US: string } }
  }
}

const LegacyHeaderTasticR2: React.FC<TasticProps> = ({ data }) => {
  const { accountDrawer, cartDrawer, megaDrawer } = useComposable()
  const { userSessionData } = useAtgUser()
  const isMobile = useBreakpointValue({ base: true, lg: false })

  return (
    <VStack>
      <LegacyHeader
        brandLogo={data.logo}
        searchComponent={
          <GlobalSearch
            algoliaIndexNameResolver={algoliaIndexNameResolver}
            algoliaQuerySuggestionsIndexNameResolver={algoliaQuerySuggestionsIndexNameResolver}
          />
        }
        onOpenAccountDrawer={() => accountDrawer.onOpen()}
        onOpenMegaDrawer={() => megaDrawer.onOpen()}
        verticalButtonProps={{
          variant: 'unstyled',
          textDecoration: 'none',
          size: 'md',
          minW: 'auto',
        }}
        cartQuantity={userSessionData?.noOfCommerceItems || 0}
        onOpenCartDrawer={() => cartDrawer.onOpen()}
      />
      {isMobile && <MegaMenuDetailsBar />}
    </VStack>
  )
}

export default LegacyHeaderTasticR2
